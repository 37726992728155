import { Session } from 'next-auth'

export const isHentai = () => {
  const value = process.env.NEXT_PUBLIC_ENV_IS_HENTAI
  return value === 'undefined' ? undefined : value === 'true'
}

export const isSafe = () => {
  const value = process.env.NEXT_PUBLIC_ENV_HENTAI_IS_SAFE
  return value === 'undefined' ? undefined : value === 'true'
}

export const isRanobe = () => {
  return process.env.NEXT_PUBLIC_ENV_CATEGORY_PROJECT === 'ranobe'
}

export const isMangaOnline = () => {
  return process.env.NEXT_PUBLIC_ENV_APP_NAME === 'MangaOnline'
}

export const isYaoi = () => {
  const value = process.env.NEXT_PUBLIC_ENV_IS_YAOI
  return value === 'undefined' ? undefined : value === 'true'
}

export const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const isMobile = (header: string) => {
  return header.match(
    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i,
  )
    ? true
    : false
}

export const isClientGeoRu = (headers: Headers) => {
  const countryCode = headers.get('country-code')
  return countryCode ? countryCode.toLowerCase() === 'ru' : false
}

export const commonArrayElements = <T>(firstArray: T[], secondArray: T[]) => {
  const setFirst = new Set(firstArray)
  return secondArray.filter((el) => setFirst.has(el))
}

export const isAdvAvailable = () => {
  return [
    'http://localhost:3000',
    'https://hotmanga.me',
    'https://xn--80aaalhzvfe9b4a.xn--80asehdb',
  ].includes(process.env.NEXT_PUBLIC_ENV_DOMAIN)
}

export const isAdvDisplay = (session: Session | null) => {
  if (!isAdvAvailable()) {
    return false
  }

  if (session && session.user.activeSubscription) {
    return false
  }

  return true
}

export const generateToken = (length: number) => {
  const chars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()'
  const charLength = chars.length
  let result = ''
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength))
  }
  return result
}

export const generateNumber = (from: number, to: number) => {
  return Math.floor(from + (to - from) * Math.random())
}

export const removeUndefined = (obj: Record<string, any>) => {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key]
    }
  }
  return obj
}
